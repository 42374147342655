import * as React from "react"

import {
    VStack,
    Box,
    Badge,
    HStack,
    Button,
    useToast,
    Image,
} from "@chakra-ui/react"

import { TypeAnimation } from "react-type-animation"
import { LogoDark } from "../logo"
import chatGPTLogo from "./chatgpt-logo-white-on-transparent-bg-small.png"

export function PromptPage({ prompt }) {
    const copiedToClipboard = "Copied to clipboard"
    const goToChatGPT = { en: "Go to ChatGPT", ko: "ChatGPT로 이동" }
    const copyButton = { en: "Click to Copy", ko: "복사하기" }
    const language = "ko"

    const promptText = prompt.prompt_ko || ""
    const tags = prompt.tags_ko || []

    const toast = useToast()
    const handleCopy = () => {
        toast.promise(navigator.clipboard.writeText(promptText), {
            // loading: "Copying to clipboard...",
            success: {
                title: "Copied to clipboard",
                colorScheme: "blackAlpha",
                position: "top",
                render: () => {
                    return (
                        <Box
                            color="white"
                            p={3}
                            bg="blackAlpha.700"
                            rounded={"md"}
                            textAlign={"center"}
                            w={"full"}
                        >
                            {copiedToClipboard[language]}
                        </Box>
                    )
                },
            },
        })
    }
    // console.log(exercise)
    return (
        <VStack w={"full"} minH={"100vh"} bg={"bg2.50"} spacing={0}>
            <VStack w={"full"} spacing={0} p={4} pb={{ base: 4 }}>
                <LogoDark />
            </VStack>
            <VStack maxW={"2xl"} p={4} w={"full"} align={"start"}>
                <HStack w={"full"} justify={"end"}>
                    <Button
                        as={"a"}
                        shadow={0}
                        href={"https://chatgpt.com/"}
                        target={"_blank"}
                        variant={"ghost"}
                        color={"cute.400"}
                        size={"sm"}
                        textDecoration={"underline"}
                    >
                        {goToChatGPT[language]}
                    </Button>
                </HStack>
                <HStack spacing={2}>
                    {tags.map((tag) => (
                        <Badge
                            key={tag}
                            bg={"brand.400"}
                            rounded={"md"}
                            color={"white"}
                            pb={".25rem"}
                        >
                            {tag}
                        </Badge>
                    ))}
                </HStack>

                <Box position={"relative"} w={"full"}>
                    <Box
                        rounded={"md"}
                        bg={"rgb(1, 22, 39)"}
                        my={1}
                        p={0}
                        overflow={"hidden"}
                    >
                        <Box
                            pr={4}
                            pl={5}
                            paddingBlock={5}
                            pt={14}
                            fontSize={"13pt"}
                            overflowX={"auto"}
                            fontFamily={
                                '"Noto Sans KR", "SF Mono", Menlo, monospace'
                            }
                        >
                            <Box color={"rgb(127, 219, 202)"} whiteSpace={"pre-line"} minH={"200px"}>
                                <TypeAnimation
                                    splitter={(str) => str.split(/(?= )/)}
                                    sequence={[promptText]}
                                    speed={{ type: 'keyStrokeDelayInMs', value: 30 }}
                                    cursor={false}
                                    omitDeletionAnimation
                                />
                            </Box>
                            {/*{promptText.split("\n").map((line, index) => (*/}
                            {/*   */}
                            {/*    */}
                            {/*))}*/}
                        </Box>
                        <Button
                            onClick={handleCopy}
                            position={"absolute"}
                            size={"sm"}
                            rounded={"md"}
                            top={5}
                            right={"1em"}
                            colorScheme={"cute"}
                            color={"white"}
                            bg={"cute.500"}
                            minW={20}
                        >
                            {copyButton[language]}
                        </Button>
                        <Image
                            boxSize={8}
                            position={"absolute"}
                            top={4}
                            left={4}
                            opacity={0.6}
                            src={chatGPTLogo}
                            alt={"ChatGPT Logo"}
                        />
                    </Box>
                </Box>
            </VStack>
        </VStack>
    )
}
